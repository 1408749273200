import './App.css';
import StartPage from './StartPage';
import PrivacyPolicy from './PrivacyPolicy';
import PersonalityPrediction from './PersonalityPrediction';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {EmotionContext} from "./EmotionProvider";
import React, { useState, useContext, createContext } from 'react';




function App() {
    const [ emotions, setEmotions ] = useState([]);

    
  return (
    <>
            <Router>
                <EmotionContext.Provider value={{emotions, setEmotions}}>
                    <Route path='/' exact component={StartPage}/>
                    <Route path='/privacypolicy' exact component={PrivacyPolicy}/>
                    <Route path='/personalityprediction/:email' exact component={PersonalityPrediction}/>
                </EmotionContext.Provider>
            </Router>
    </>
  );
}

export default App;

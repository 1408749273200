import './PersonalityPrediction.css'
import CoinsNavbar from './CoinsNavbar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import React, {useContext, useState, useCallback, useEffect, useLocation} from "react";
import { useParams } from 'react-router-dom'
import RadarChart from 'react-svg-radar-chart';
//import RadarChart from "react-chartjs" 
//import NestedJsonTable from "react-nested-json-table";

function PersonalityPrediction() {
    const { email } = useParams();
    const [clipemotions, setClipemotions] = useState({
            "clip1":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00
                
            },
            "clip2":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00 
            },
            "clip3":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00 
            },
            "clip4":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00 
            },
            "clip5":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00 
            },
            "clip6":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00 
            },
            "clip7":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00 
            },
            "clip8":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00 
            },
            "clip9":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00 
            },
            "clip10":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00 
            },
            "clip11":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00 
            },
            "clip12":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00 
            },
            "clip13":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00 
            },
            "clip14":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00 
            },
            "clip15":
            {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00 
            } 
    })


    const [emotionsMean, setEmotionsMean] = useState({
        "emotion_mean": {
            "averages": {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00
            }
        }
    })

    const [emotionsSum, setEmotionsSum] = useState({
        "emotion_sum": {
            "sum": {
                "angry": 0.00,
                "disgusted": 0.00,
                "fearful": 0.00,
                "happy": 0.00,
                "neutral": 0.00,
                "sad": 0.00,
                "surprised": 0.00
            }
        }
    })

    const [predictionData, setPredictionData] = useState({
        "agreeableness": -1,
        "authority_respect": -1,
        "conscientiousness": -1,
        "conservation": -1,
        "ethical_risk_likelihood": -1,
        "ethical_risk_perceived": -1,
        "extraversion": -1,
        "fairness_reciprocity": -1,
        "financial_risk_likelihood": -1,
        "financial_risk_perceived": -1,
        "harm_care": -1,
        "health_risk_likelihood": -1,
        "health_risk_perceived": -1,
        "in_group_loyality": -1,
        "neuroticism": -1,
        "openness_to_experience": -1,
        "purity_sanctity": -1,
        "recreational_risk_likelihood": -1,
        "recreational_risk_perceived": -1,
        "social_risk_likelihood": -1,
        "social_risk_perceived": -1,
        "transcendence": -1
    })


    const fetchVideoEmotionData = useCallback(async() => {
        const requestOptions = {
            method: 'GET'
        }; 
        const response = await fetch('https://facerecognition.galaxyadvisors.com/facerecognition/getuseremotionspervideo/' + email, requestOptions);
        const videoemotiondata = await response.json();
        setClipemotions(videoemotiondata.result.emotionspervideo)
        setEmotionsMean(videoemotiondata.result.meanemotions)
        setEmotionsSum(videoemotiondata.result.sumemotions)
    }, [email]);


    const fetchPredictionData = useCallback(async() => {
        const requestOptions = {
            method: 'GET'
        }; 
        const response = await fetch('https://facerecognition.galaxyadvisors.com/facerecognition/getpersonalityprediction/' + email, requestOptions);
        const predictiondata = await response.json();
        setPredictionData(predictiondata)
        console.log(predictiondata)
    }, [email]);


    function levelTranslater(levelscore) {
        let leveltranslated;
        if(levelscore == 0) {
            leveltranslated = "low";
        }
        else if (levelscore == 1) {
            leveltranslated = "medium";
        }
        else if (levelscore == 2) {
            leveltranslated = "high";
        }
        else{
            leveltranslated = "calculating ...";
        }
        return leveltranslated;
    }

    useEffect(() =>  {
        let ignore = false;
        if (!ignore){
            fetchVideoEmotionData();
            fetchPredictionData();
        }
        return () => { ignore = true; }
    },[]);

    return (
        <>
            <CoinsNavbar />
            <LazyLoadImage
                className="background"
            />
{/*             <div className='videoemotions'> 
                <p>You showed following emotions:</p>       
                {{"clips" : clipemotions} && (() =>
                    <NestedJsonTable data={{"clips" : clipemotions}} expandAll={false} />
                    )()
                }
            </div> */}

            <div className = 'personalities'>
                <p>You showed following personality:</p>
                    <div classname = "ffiradarchart">
                        <h3>FFI</h3>
                            <RadarChart
                                captions={{
                                            // columns
                                            agreeableness: "Agreeableness",
                                            conscientiousness: "Conscientiousness",
                                            extraversion: "Extraversion",
                                            neuroticism: "Neuroticism",
                                            openness_to_experience: "Openness to Experience"
                                        }}
                                        data={[
                                            {
                                                data: {
                                                    agreeableness: (predictionData.agreeableness + 1) / 3,
                                                    conscientiousness: (predictionData.conscientiousness + 1) / 3,
                                                    extraversion: (predictionData.extraversion + 1) / 3,
                                                    neuroticism: (predictionData.neuroticism + 1) / 3,
                                                    openness_to_experience: (predictionData.openness_to_experience + 1) / 3
                                                },
                                                meta: {color: 'red', zoomDistance: 200}
                                            },
                                        ]}
                                        options={{
                                            dots:true,
                                            scale: {
                                                ticks:{
                                                    beginAtZero: true,
                                                    max: 1,
                                                    display: true
                                                    //stepSize:1
                                                    //min: 1,
                                                    //max: 3,
                                                    //stepSize:1                                                
                                                }
                                            },
                                            zoomDistance: 1.4,
                                            captionProps: () => ({
                                                fontSize: 10,
                                                textAnchor: 'middle'
                                            }),
                                            dotProps: () => ({
                                                className: 'dot',
                                                mouseEnter: (dot) => { console.log(dot) },
                                                mouseLeave: (dot) => { console.log(dot) }
                                              })
                                        }}

                                        size={400}


                                    />
                    </div> 
                    <div classname = "schwartzradarchart">
                        <h3>Schwartz's Values</h3>
                            <RadarChart
                                captions={{
                                            // columns
                                            authority_respect: "Authority/Respect",
                                            fairness_reciprocity: "Fairness/Reciprocity",                                            
                                            in_group_loyality: "Ingroup/Loyalty",
                                            purity_sanctity: "Purity/Sanctity",
                                            harm_care: "Harm/Care"
                                        }}
                                        data={[
                                            {
                                                data: {
                                                    authority_respect: (predictionData.authority_respect + 1) / 3,
                                                    fairness_reciprocity: (predictionData.fairness_reciprocity + 1) / 3,
                                                    harm_care: (predictionData.harm_care + 1) / 3,
                                                    in_group_loyality: (predictionData.in_group_loyality + 1) / 3,
                                                    purity_sanctity: (predictionData.purity_sanctity + 1) / 3,
                                                    harm_care: (predictionData.harm_care + 1) / 3
                                                },
                                                meta: {color: 'grey', zoomDistance: 200}
                                            },
                                        ]}
                                        options={{
                                            dots:true,
                                            scale: {
                                                ticks:{
                                                    min: 1,
                                                    max: 3,
                                                    stepSize:1                                                
                                                }
                                            },
                                            zoomDistance: 1.4,
                                            captionProps: () => ({
                                                fontSize: 10,
                                                textAnchor: 'middle'
                                            })
                                        }}

                                        size={400}


                                    />
                
                </div>    
                <div classname = "moralradarchart">
                        <h3>Moral Foundation Values</h3>
                            <RadarChart
                                captions={{
                                            // columns
                                            conservation: "Conservation",
                                            transcendence: "Transcendence"
                                        }}
                                        data={[
                                            {
                                                data: {
                                                    conservation: (predictionData.conservation + 1) / 3,
                                                    transcendence: (predictionData.transcendence + 1) / 3
                                                },
                                                meta: {color: 'red', zoomDistance: 200}
                                            },
                                        ]}
                                        options={{
                                            dots:true,
                                            scale: {
                                                ticks:{
                                                    min: 1,
                                                    max: 3,
                                                    stepSize:1                                                
                                                }
                                            },
                                            zoomDistance: 1.4,
                                            captionProps: () => ({
                                                fontSize: 10,
                                                textAnchor: 'middle'
                                            })
                                        }}

                                        size={400}


                                    />
                    </div> 
                    <div classname = "dospertradarchart">
                        <h3>DOSPERT </h3>
                            <RadarChart
                                captions={{
                                            // columns
                                            ethical_risk_likelihood: "Ethical  Likelihood",
                                            ethical_risk_perceived: "Ethical Perceived",
                                            financial_risk_likelihood: "Financial Likelihood",
                                            financial_risk_perceived: "Financial Perceived",
                                            health_risk_likelihood: "Health Likelihood",
                                            health_risk_perceived:"Health Perceived",
                                            recreational_risk_likelihood: "Recreational Likelihood",
                                            recreational_risk_perceived: "Recreational Perceived",
                                            social_risk_likelihood: "Social Likelihood",
                                            social_risk_perceived: "Social Perceived"
                                        }}
                                        data={[
                                            {
                                                data: {
                                                    ethical_risk_likelihood: (predictionData.ethical_risk_likelihood + 1) / 3,
                                                    ethical_risk_perceived: (predictionData.ethical_risk_perceived + 1) / 3,
                                                    financial_risk_likelihood: (predictionData.financial_risk_likelihood+ 1) / 3,
                                                    financial_risk_perceived: (predictionData.financial_risk_perceived + 1) / 3,
                                                    health_risk_likelihood: (predictionData.health_risk_likelihood + 1) / 3,
                                                    health_risk_perceived: (predictionData.health_risk_perceived + 1) / 3,
                                                    recreational_risk_likelihood: (predictionData.recreational_risk_likelihood + 1) / 3,
                                                    recreational_risk_perceived: (predictionData.recreational_risk_perceived + 1) / 3,
                                                    social_risk_likelihood: (predictionData.social_risk_likelihood + 1) / 3, 
                                                    social_risk_perceived: (predictionData.social_risk_perceived + 1) / 3
                                                },
                                                meta: {color: 'blue', zoomDistance: 200}
                                            },
                                        ]}
                                        options={{
                                            dots:true,
                                            scale: {
                                                ticks:{
                                                    min: 1,
                                                    max: 3,
                                                    stepSize:1                                                
                                                }
                                            },
                                            zoomDistance: 1.4,
                                            captionProps: () => ({
                                                fontSize: 10,
                                                textAnchor: 'middle'
                                            })
                                        }}

                                        size={400}


                                    />
                    </div>       
            </div>
        </>
    );
}

export default PersonalityPrediction;
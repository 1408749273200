import ReactPlayer from "react-player";
import React from "react";

const TriggerVideo = (props) =>{


    return(
        <div style={{pointerEvents: "none"}}>
            <div className="video">
                <div className="videoBox">
                    <ReactPlayer
                        playing={props.show}
                        url={props.video}
                        config={{youtube: {playerVars: {disablekb: 1}}}}
                        onProgress={props.watch}
                        onEnded={props.end}
                        width='864px'
                        height='486px'
                    />
                </div>
            </div>
        </div>

    )
}

export default TriggerVideo


import React, { useContext, useEffect, useState } from 'react';
import * as faceapi from 'face-api.js';
import { EmotionContext } from "./EmotionProvider";
import StartPage from "./StartPage";


const Camera = () => {

    const camera = React.useRef();
    const { emotions, setEmotions } = useContext(EmotionContext); //useContext to give tracked emotions to StartPage Component
    const [neutral, setNeutral] = useState(0);


    /*
    - The useEffect is executed once and renders the objects again.
    - const promise loads all tensorflow models from the faceapi into a Promis. 
    - getUserMedia accesses the camera.
    - Trough the apis the face is recognized and tracked. A canvas is drawn over the face which is used for measuring emotions.
    - The measured emotions are stored in the emotions variable every millisecond. The variable is then an array with all emotions, which are stored every 100 milliseconds.
    - When changing the page, we always had an error message because no face could be recognized anymore. For this we have created 
    the variable unlisten to turn off this error and the moment to turn off the camera.
    - In the return, the video created by the camera is output to the website. 
    */
    useEffect(() => {
        let stream;
        const promise = Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
            faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
            faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
            faceapi.nets.faceExpressionNet.loadFromUri('/models')
        ]).then(async () => {
            const constraints = { audio: false, video: { width: 1080, height: 720 } };
            const video = document.querySelector("video");
            try {
                stream = await navigator.mediaDevices
                    .getUserMedia(constraints);
                video.srcObject = stream;
                video.onloadedmetadata = function (e) {
                    video.play();
                };
            } catch (err) {
                console.log(err.name + ": " + err.message);
            }
            return stream;
        })

        let unlisten = [];
        camera.current.addEventListener('play', () => {
            const canvas = faceapi.createCanvasFromMedia(camera.current)
            document.body.append(canvas)
            const displaySize = { width: camera.current.width, height: camera.current.height }
            faceapi.matchDimensions(canvas, displaySize)
            const interval = setInterval(async () => {
                const detections = await faceapi.detectAllFaces(camera.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions()
                const resizedDetections = faceapi.resizeResults(detections, displaySize)

                if (typeof detections[0] === 'undefined') {
                    setEmotions(emotions => [...emotions, 'undefined']); //detections[0]['expressions']
                } else {
                    var facialexpressionobject = detections[0]['expressions']
                    for(var propertyName in facialexpressionobject){
                        if(typeof facialexpressionobject[propertyName] === 'number'){
                            facialexpressionobject[propertyName] = facialexpressionobject[propertyName].toFixed(5);
                        }
                    }
                    setEmotions(emotions => [...emotions, facialexpressionobject]); //detections[0]['expressions']

                    setNeutral(detections[0]['expressions']['neutral']); //Catch one emotion
                }

                canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
                faceapi.draw.drawFaceLandmarks(canvas, resizedDetections)
            }, 100);
            unlisten.push(() => clearInterval(interval));
        });
        return () => {
            stream.getTracks()[0].stop();
            unlisten.forEach(unlisten => unlisten());
        };
    }, [])

    return (

        <video ref={camera} width="180" height="140" autoPlay muted></video>

    )
}


export default Camera;